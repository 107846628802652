import React, { useState, useEffect, useRef } from 'react';
import SingleDatePicker from '../Components/SingleDatePickerForAddBooking'
import { saveBooking, getBookedSlots } from '../firebase/services/bookingsData';
import { toast, Toast } from 'react-toastify';
import { getAllAuthorizedUsers } from '../firebase/services/UserData' // Import the new function

import ClearableProp from '../Components/SingleDatePicker';
import Calendar from '../Components/calender' // Assuming you have a Calendar component
import { useAuth } from '../context/AuthContext';


const AddBooking = ({ onClose, onSave, booking }) => {
  const [editingBooking, setEditingBooking] = useState(booking || {});
  const [isTimeDropdownOpen, setIsTimeDropdownOpen] = useState(false);
  const [isAmenityDropdownOpen, setIsAmenityDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Choose Amenity');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const timeDropdownRef = useRef(null);
  const {user} = useAuth();
  const buttonRef = useRef(null);
  const optionsRef = useRef(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);
  const dateInputRef = useRef(null);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [authorizedUsers, setAuthorizedUsers] = useState([]); // For storing authorized users
  const dropdownRef = useRef(null);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const userDropdownRef = useRef(null);
  
  
  const toggleOptions = () => setIsAmenityDropdownOpen(!isAmenityDropdownOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsAmenityDropdownOpen(false);
    handleInputChange('amenity', option);
  };

  useEffect(() => {
    // console.log("User object from useAuth:", user); // Debugging log
  }, [user]);


  useEffect(() => {
    // Fetch all authorized users when component mounts
    const fetchUsers = async () => {
      try {
        const users = await getAllAuthorizedUsers();
        setAuthorizedUsers(users);
      } catch (error) {
        console.error('Error fetching authorized users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleUserSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setIsUserDropdownOpen(term.length > 0);
    if (term === '') {
      setFilteredUsers([]);
    } else {
      const filtered = authorizedUsers.filter((user) =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSearchTerm(`${user.firstName} ${user.lastName}`);
    setIsUserDropdownOpen(false);
  };


  useEffect(() => {
    setEditingBooking(booking || {});
    setSelectedOption(booking?.amenity || 'Choose Amenity');
  }, [booking]);

  useEffect(() => {
    console.log("User object from useAuth:", user); // Add this for debugging
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (timeDropdownRef.current && !timeDropdownRef.current.contains(event.target)) {
        setIsTimeDropdownOpen(false);
      }
      if (optionsRef.current && !optionsRef.current.contains(event.target) &&
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsAmenityDropdownOpen(false);
      }
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }

      if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
        setIsUserDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const handleDateSelection = (formattedDate) => {
  //   handleInputChange('slotDate', formattedDate);
  //   setIsCalendarOpen(false);
  // };

  // const handleDateSelection = (date) => {
  //   if (date) {
  //     handleInputChange('slotDate', date);
  //   }
  //   setIsCalendarOpen(false);
  // };

  const handleInputChange = (name, value) => {
    setEditingBooking(prev => ({ ...prev, [name]: value }));
  };

  

  // const handleSaveEdit = () => {
  //   // onSave(editingBooking);
  //   // onClose();
  //   if(typeof onSave ==="function"){
  //     onSave(editingBooking);
  //   }

  //   if(typeof onClose ==="function"){
  //     onClose();
  //   }
  // };

  // const handleSaveEdit = async () =>{
  //   if (!editingBooking.name || !editingBooking.amenity || !editingBooking.slotDate || !editingBooking.slotTime){
  //     toast.error('Please fill in all fields');
  //     return;
  //   }

  //   try {
  //     //add any additional fields you want to save

  //     const bookingData={
  //       ...editingBooking,
  //       userId:user.id, //replace with actual user id
      
  //       status:'pending' , //or any default status
  //       createdAt: new Date().toISOString()
  //     };
  //     console.log(bookingData);
      

  //     const bookingId = await saveBooking(bookingData);
  //     toast.success('Booking saved successfully');

  //     if(typeof onSave === "function"){
  //       onSave(bookingData);
  //     }

  //     if(typeof onClose === "function"){
  //       onClose();
  //     }
      
  //   } catch (error) {
  //     console.log("Error saving booking", error);
  //     toast.error('Failed to save booking. Please try again');
      
  //   }
  // }

  // const handleSaveEdit = async () => {

  //   if (!user || !user.id) {
  //     toast.error('User is not authenticated. Please log in.');
  //     return;
  //   }

  //   if (!editingBooking.name || !selectedOption ==='Choose Amenity' || !editingBooking.slotDate || !editingBooking.slotTime) {
  //     toast.error('Please fill in all fields');
  //     return;
  //   }

  //   try {
  //     // Parse the date string to a Date object
  //     const bookingDate = new Date(editingBooking.slotDate);
  //     if (isNaN(bookingDate.getTime())) {
  //       toast.error('Invalid date. Please select a valid date.');
  //       return;
  //     }

  //     // Extract first name and last name
  //     const [firstName, ...lastNameParts] = editingBooking.name.split(' ');
  //     const lastName = lastNameParts.join(' ');

  //     const bookingData = {
  //       amenityName: selectedOption,
  //       createdAt: new Date(),
  //       date: bookingDate,
  //       timeSlot: editingBooking.slotTime,
  //       type: "amenities",
  //       userFirstName: firstName,
  //       userId: user.id,
  //       userLastName: lastName || "",
  //       userPhoneNumber: user.phoneNumber || "" // Assuming user object has phoneNumber
  //     };

  //     console.log('Booking data to be saved:', bookingData); // Add this line for debugging


  //     const bookingId = await saveBooking(bookingData);
  //     console.log('Booking saved with ID:', bookingId); // Add this line for debugging
  //     toast.success('Booking saved successfully');

  //     if (typeof onSave === "function") {
  //       onSave(bookingData);
  //     }

  //     if (typeof onClose === "function") {
  //       onClose();
  //     }
  //   } catch (error) {
  //     console.error("Error saving booking:", error);
  //     toast.error(`Failed to save booking. Error: ${error.message}`);
  //   }
  // };

  const handleSaveEdit = async () => {
    if (!selectedUser || !selectedOption || !editingBooking.slotDate || !editingBooking.slotTime) {
      toast.error('Please fill in all fields');
      return;
    }

    if (!user) {
      toast.error('You must be logged in to make a booking');
      return;
    }
    console.log(selectedUser)

    try {
      const bookingDate = new Date(editingBooking.slotDate);
      if (isNaN(bookingDate.getTime())) {
        toast.error('Invalid date. Please select a valid date.');
        return;
      }

      const bookingData = {
        amenityName: selectedOption,
        createdAt: new Date(),
        date: bookingDate,
        timeSlot: editingBooking.slotTime,
        type: 'amenities', // Use the selected user's ID, not the logged-in user's ID
        phoneNumber: selectedUser.phoneNumber || '',// Add this to track who created the booking
      };

      console.log('Booking data to be saved:', bookingData); // Debugging log

      const bookingId = await saveBooking(bookingData);
      console.log('Booking saved with ID:', bookingId); // Debugging log
      toast.success('Booking saved successfully');

      if (typeof onSave === 'function') {
        onSave(bookingData);
      }

      if (typeof onClose === 'function') {
        onClose();
      }
    } catch (error) {
      console.error('Error saving booking:', error);
      toast.error(`Failed to save booking. Error: ${error.message}`);
    }
  };

  const handleClearAll = () => {
    setEditingBooking({});
    setSelectedOption('Choose Amenity');
  };

  const availableTimeSlots = [
    "1:00 am",
    "2:00 am",
    "3:00 am",
    "4:00 am",
    "5:00 am",
    "6:00 am",
    "7:00 am",
    "8:00 am",
    "9:00 am",
    "10:00 am",
    "11:00 am",
    "12:00 pm",
    "1:00 pm",
    "2:00 pm",
    "3:00 pm",
    "4:00 pm",
    "5:00 pm",
    "6:00 pm",
    "7:00 pm",
    "8:00 pm",
    "9:00 pm",
    "10:00 pm",
    "11:00 pm"
];


  useEffect(() => {
    const fetchBookedSlots = async () => {
      if (editingBooking.slotDate && selectedOption) {
        const slots = await getBookedSlots(editingBooking.slotDate, selectedOption);
        setBookedSlots(slots);
      }
    };

    fetchBookedSlots();
  }, [editingBooking.slotDate, selectedOption]);


  const handleDateSelection = (formattedDate) => {
    handleInputChange('slotDate', formattedDate);
    setIsCalendarOpen(false);
    // Reset time slot when date changes
    handleInputChange('slotTime', '');
  };
  
  const handleTimeSlotSelection = (time) => {
    if (bookedSlots.includes(time)) {
      toast.error('This slot is already booked. Please choose a different time.');
    } else {
      handleInputChange('slotTime', time);
      setIsTimeDropdownOpen(false);
    }
  };

  const options = ["Swimming pool", "Gym", "Multi-Purpose Court", "Banquet Hall", "Party Hall", "Jacuzzi & Spa", "Indoor Theatre", "Movie Lawn", "Table Tennis Room"];

  const dropdownSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.2345 7.06754L9.98453 13.3175C9.92648 13.3756 9.85755 13.4217 9.78168 13.4532C9.7058 13.4847 9.62447 13.5008 9.54234 13.5008C9.46021 13.5008 9.37888 13.4847 9.303 13.4532C9.22713 13.4217 9.1582 13.3756 9.10015 13.3175L2.85015 7.06754C2.73288 6.95026 2.66699 6.7912 2.66699 6.62535C2.66699 6.4595 2.73288 6.30044 2.85015 6.18316C2.96743 6.06588 3.12649 6 3.29234 6C3.45819 6 3.61725 6.06588 3.73453 6.18316L9.54234 11.9918L15.3502 6.18316C15.4082 6.12509 15.4772 6.07903 15.553 6.0476C15.6289 6.01617 15.7102 6 15.7923 6C15.8745 6 15.9558 6.01617 16.0317 6.0476C16.1075 6.07903 16.1765 6.12509 16.2345 6.18316C16.2926 6.24123 16.3387 6.31017 16.3701 6.38604C16.4015 6.46191 16.4177 6.54323 16.4177 6.62535C16.4177 6.70747 16.4015 6.78879 16.3701 6.86466C16.3387 6.94053 16.2926 7.00947 16.2345 7.06754Z" fill="#6B7280"/>
    </svg>
  );

  const inputStyle = {
    width: '100%',
    padding: '8px',
    border: '1px solid var(--Gray-300, #D1D5DB)',
    borderRadius: '6px',
    color: 'var(--Gray-500, #4B5563)',
    // color
    // color:'red',
    // fontFamily: 'Plus Jakarta Sans, sans-serif',
    fontFamily:'Plus_Jakarta',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    backgroundColor: 'white',
    height: '44px',
    outline: 'none',
  };

  const labelStyle = {
    color: 'var(--Gray-500, #4B5563)',
    // fontFamily: 'Plus Jakarta Sans, sans-serif',
    fontFamily:'Plus_Jakarta',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    marginBottom: '4px',
    display: 'block',
  };

  const clearButtonStyle = {
    color: 'var(--Gray-900, #030712)',
    // fontFamily: "Plus Jakarta Sans, sans-serif",
    fontFamily:'Plus_Jakarta',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    textDecorationLine: 'underline',
    marginLeft: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  };

  const saveButtonStyle = {
    display: 'flex',
    padding: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    background: 'var(--Gray-900, #030712)',
    color: 'var(--Gray-25, #F9FAFB)',
    textAlign: 'center',
    // fontFamily: 'Plus Jakarta Sans, sans-serif',
    fontFamily:'Plus_Jakarta',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
  };

  const dropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 1000,
    marginTop: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    borderRadius: '8px',
    width: '100%',
    border: '1px solid #D1D5DB',
    color: '#6B7280'
  };

  const dropdownItemStyle = {
    padding: '8px 16px',
    cursor: 'pointer',
    hover: {
      backgroundColor: '#F3F4F6',
    },
    color: 'var(--Gray-400, #6B7280)',
    fontSize: '14px',
    borderBottom: '1px solid var(--Gray-100, #E5E7EB)'
  };

  return (
    <div className="fixed inset-0  flex justify-center items-center"style={{fontFamily:'Plus_Jakarta',}}>
      <div className="bg-white rounded-2xl w-[428px]" style={{fontFamily:'Plus_Jakarta',}}>
        <div className="p-6 border-b border-gray-200"style={{fontFamily:'Plus_Jakarta',}}>
          <div className="flex justify-between items-center">
            <div className="w-6"></div>
            <h2 style={{
              color: 'var(--Gray-900, #030712)',
              fontFamily: 'Plus_Jakarta',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '28px'
            }}>
              Add Booking
            </h2>
            <button onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M19.2806 18.2194C19.3502 18.289 19.4055 18.3718 19.4432 18.4628C19.4809 18.5539 19.5003 18.6514 19.5003 18.75C19.5003 18.8485 19.4809 18.9461 19.4432 19.0372C19.4055 19.1282 19.3502 19.2109 19.2806 19.2806C19.2109 19.3503 19.1281 19.4056 19.0371 19.4433C18.9461 19.481 18.8485 19.5004 18.7499 19.5004C18.6514 19.5004 18.5538 19.481 18.4628 19.4433C18.3717 19.4056 18.289 19.3503 18.2193 19.2806L11.9999 13.0603L5.78055 19.2806C5.63982 19.4213 5.44895 19.5004 5.24993 19.5004C5.05091 19.5004 4.86003 19.4213 4.7193 19.2806C4.57857 19.1399 4.49951 18.949 4.49951 18.75C4.49951 18.551 4.57857 18.3601 4.7193 18.2194L10.9396 12L4.7193 5.78061C4.57857 5.63988 4.49951 5.44901 4.49951 5.24999C4.49951 5.05097 4.57857 4.8601 4.7193 4.71936C4.86003 4.57863 5.05091 4.49957 5.24993 4.49957C5.44895 4.49957 5.63982 4.57863 5.78055 4.71936L11.9999 10.9397L18.2193 4.71936C18.36 4.57863 18.5509 4.49957 18.7499 4.49957C18.949 4.49957 19.1398 4.57863 19.2806 4.71936C19.4213 4.8601 19.5003 5.05097 19.5003 5.24999C19.5003 5.44901 19.4213 5.63988 19.2806 5.78061L13.0602 12L19.2806 18.2194Z" fill="#030712"/>
              </svg>
            </button>
          </div>
        </div>
        
        <div className="p-6">
          <div className="mb-4" style={{ position: 'relative',fontFamily:'Plus_Jakarta' }}>
            <label style={labelStyle}>
              Search User
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                
                placeholder="Search for user"
                style={inputStyle}
                value={searchTerm}
                onChange={handleUserSearch}
              />
            </div>
            {isUserDropdownOpen && (
              <div ref={userDropdownRef} 
              style={{
                ...dropdownStyle,
                maxHeight: '150px',  // Limit height to around 5 items (~40px per item)
                overflowY: 'auto',  // Enable vertical scrolling
              }}>
                {filteredUsers.length > 0 ? (
                  filteredUsers.map((user, index) => (
                    <div
                      key={index}
                      onClick={() => handleUserSelect(user)}
                      style={{
                        ...dropdownItemStyle,
                        borderBottom: index === filteredUsers.length - 1 ? 'none' : '1px solid var(--Gray-100, #E5E7EB)'
                      }}
                    >
                      <div className='flex justify-between'>
                        <span>{user.firstName} {user.lastName}</span>
                        <span>{user.wing} - {user.flatNumber}</span>
                      </div>
                  </div>
                  ))
                ) : (
                  <div style={dropdownItemStyle}>No users found</div>
                )}
              </div>
            )}
          </div>
          
          <div style={{ marginBottom: '16px', position: 'relative' }}>
            <label style={labelStyle}>
              Choose Amenity
            </label>
            <button 
              ref={buttonRef}
              onClick={toggleOptions}
              style={{
                ...inputStyle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                backgroundColor: 'white',
                fontFamily:'Plus_Jakarta'
              }}
            >
              <span>{selectedOption}</span>
              {dropdownSvg}
            </button>
            {isAmenityDropdownOpen && (
              <div ref={optionsRef} 
              style={{
                ...dropdownStyle,
                maxHeight: '150px',  // Limit height to around 5 items (~40px per item)
                overflowY: 'auto',  // Enable vertical scrolling
              }}>
                {options.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => handleOptionClick(option)}
                    style={{
                      ...dropdownItemStyle,
                      borderBottom: index === options.length - 1 ? 'none' : '1px solid var(--Gray-100, #E5E7EB)'
                    }}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
          
          <div className="mb-1">
            <label style={labelStyle}>
              Choose Date & Time
            </label>
            <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ flex: 1, position: 'relative' }}>
                <button 
                ref={dateInputRef}
                  onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                  style={{
                    ...inputStyle,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                  }}
                >
                  <span>{editingBooking.slotDate || "Select Date"}</span>
                  {dropdownSvg}
                </button>
                {isCalendarOpen && (
                  <div ref={calendarRef} style={{
                    position: 'absolute',
                    bottom: '100%',
                    left: 0,
                    zIndex: 1000,
                    marginTop: '8px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  }}>
                    <SingleDatePicker onDateSelect={handleDateSelection} />
                  </div>
                )}
              </div>
              <div style={{ flex: 1, position: 'relative' }} ref={timeDropdownRef}>
                <button 
                  onClick={() => setIsTimeDropdownOpen(!isTimeDropdownOpen)}
                  style={{
                    ...inputStyle,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                  }}
                >
                  <span>{editingBooking.slotTime || "Select Time"}</span>
                  {dropdownSvg}
                </button>
                {isTimeDropdownOpen && (
                  <div style={{
                    ...dropdownStyle,
                    maxHeight: '150px',
                    overflowY: 'auto',
                  }}>
                    {availableTimeSlots.map((time, index) => (
                      <div
                        key={index}
                        onClick={() => handleTimeSlotSelection(time)}
                        style={{
                          ...dropdownItemStyle,
                          borderBottom: index === availableTimeSlots.length - 1 ? 'none' : '1px solid var(--Gray-100, #E5E7EB)',
                          backgroundColor: bookedSlots.includes(time) ? '#E5E7EB' : 'white',
                          color: bookedSlots.includes(time) ? '#9CA3AF' : 'var(--Gray-400, #6B7280)',
                          cursor: bookedSlots.includes(time) ? 'not-allowed' : 'pointer',
                        }}
                      >
                        {time} {bookedSlots.includes(time) && '(Booked)'}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '24px', borderTop: '1px solid #E5E7EB' ,fontFamily:'Plus_Jakarta'}}>
          <button onClick={handleClearAll} style={clearButtonStyle}>
            Clear All
          </button>
          <button onClick={handleSaveEdit} style={saveButtonStyle}>
            Add Booking
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBooking;