import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';
import styles from './CustomScrollbar.module.css';

const Layout = () => {
  return (
    <div className="flex h-screen overflow-hidden font-plus-jakarta" style={{ fontFamily: "Plus_Jakarta", background: 'var(--Gray-25, #F9FAFB)' }}>
      {/* Left Sidebar (320px) */}
      <div className="w-[320px] overflow-y-auto">
        <SideBar />
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-x-hidden overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;