import React from 'react';

const PersonalInfoForm = ({ userInfo }) => {
  const defaultText = 'N/A';

  return (
    <div className="bg-white rounded-xl border shadow-sm p-5 max-w-sm" >
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label style={{fontSize:'12px',color:'#6B7280'}} className="block text-sm font-medium text-gray-500 mb-1">First Name</label>
            <input style={{fontSize: "16px",color:'#030712', fontWeight:'500'}} type="text" value={userInfo?.firstName || defaultText} readOnly className="w-full py-2 border-gray-300 rounded-md text-gray-900 text-base" />
          </div>
          <div>
            <label style={{fontSize:'12px',color:'#6B7280'}} className="block text-sm font-medium text-gray-500 mb-1">Last Name</label>
            <input style={{fontSize: "16px",color:'#030712', fontWeight:'500'}} type="text" value={userInfo?.lastName || defaultText} readOnly className="w-full py-2 border-gray-300 rounded-md text-gray-900 text-base" />
          </div>
        </div>
        <div>
          <label style={{fontSize:'12px',color:'#6B7280'}} className="block text-sm font-medium text-gray-500 mb-1">Email</label>
          <input style={{fontSize: "16px",color:'#030712', fontWeight:'500'}} type="email" value={userInfo?.email || defaultText} readOnly className="w-full py-2 border-gray-300 rounded-md text-gray-900 text-base" />
        </div>
        <div>
          <label style={{fontSize:'12px',color:'#6B7280'}} className="block text-sm font-medium text-gray-500 mb-1">Phone</label>
          <input style={{fontSize: "16px",color:'#030712', fontWeight:'500'}} type="tel" value={userInfo?.phoneNumber || defaultText} readOnly className="w-full py-2 border-gray-300 rounded-md text-gray-900 text-base" />
        </div>
        <div>
          <label style={{fontSize:'12px',color:'#6B7280'}} className="block text-sm font-medium text-gray-500 mb-1">Unit/Apartment Number</label>
          <input style={{fontSize: "16px",color:'#030712', fontWeight:'500'}} type="text" value={userInfo ? `${userInfo.wing || ''} - ${userInfo.flatNumber || ''}` : defaultText} readOnly className="w-full py-2 border-gray-300 rounded-md text-gray-900 text-base" />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoForm;