import React, { createContext, useContext, useState } from 'react';
import { addStaffUser } from '../firebase/services/UserService';
import { toast } from 'react-toastify';

const StaffContext = createContext();

export const useStaff = () => useContext(StaffContext);

export const StaffProvider = ({ children }) => {
  const [isAddingStaff, setIsAddingStaff] = useState(false);

  const addStaffMember = async (staffData) => {
    setIsAddingStaff(true);
    try {
      // Use the existing addStaffUser function from services
      const result = await addStaffUser({
        email: staffData.email,
        password: staffData.password,
        firstName: staffData.firstName,
        lastName: staffData.lastName,
        role: staffData.role
      });

      console.log('Staff addition result:', result);
      // toast.success('Staff member added successfully');
      if (result && result.firestoreId) {
        toast.success('Staff member added successfully');
        return result;
      } else {
        throw new Error('Failed to add staff member');
      }
      // return result;
    } catch (error) {
      console.error('Error adding staff member:', error);
      if (error.response && error.response.status === 500) {
        toast.error('Server error occurred. Please try again later.');
      } else if (error.message === "This email is already registered. Please use a different email address.") {
        toast.error(error.message);
      } else {
        toast.error(`Failed to add staff member: ${error.message}`);
      }
      throw error; // Re-throw the error so the modal can handle it
    } finally {
      setIsAddingStaff(false);
    }
  };

  return (
    <StaffContext.Provider value={{ addStaffMember, isAddingStaff }}>
      {children}
    </StaffContext.Provider>
  );
};